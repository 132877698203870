import {accounts, rates} from "./data";


export const getTotalBalance = () => {
    let total = 0;
    accounts.forEach(account => {
        account.wallets.forEach((wallet) => {
            wallet.tokens.forEach((token) => {
                // @ts-ignore
                total += token.amount * rates[token.currency]
            })
        })
        account.assets.forEach((asset) => {
            // @ts-ignore
            total += asset.amount * rates[asset.currency];
        })
    })

    return total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// @ts-ignore
export const getAccountBalance = (account, withoutFormat = false) => {
    let total = 0;
    // @ts-ignore
    account.wallets.forEach((wallet) => {
        // @ts-ignore
        wallet.tokens.forEach((token) => {
            // @ts-ignore
            total += token.amount * rates[token.currency]
        })
    })

    // @ts-ignore
    account.assets.forEach((asset) => {
        // @ts-ignore
        total += asset.amount * rates[asset.currency];
    })

    if (withoutFormat) return total;
    return total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// @ts-ignore
export const getWalletsBalance = (account, withoutFormat = false) => {
    let total = 0;

    // @ts-ignore
    account.wallets.forEach((wallet) => {
        // @ts-ignore
        wallet.tokens.forEach((token) => {
            // @ts-ignore
            total += token.amount * rates[token.currency]
        })
    })

    if (withoutFormat) {
        return total;
    }

    return total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// @ts-ignore
export const getWalletBalance = (wallet) => {
    let total = 0;

    // @ts-ignore
    wallet.tokens.forEach((token) => {
        // @ts-ignore
        total += token.amount * rates[token.currency]
    })

    return total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


// @ts-ignore
export const getAssetsBalance = (account, withoutFormat = false) => {
    let total = 0;

    // @ts-ignore
    account.assets.forEach((asset) => {
        // @ts-ignore
        total += asset.amount * rates[asset.currency];
    })

    if (withoutFormat) {
        return total
    }
    return total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

