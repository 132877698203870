import s from './Home.module.scss';
import cn from 'classnames'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {useState} from "react";
import {getAccountBalance, getTotalBalance} from "../../utils";
import {accounts, rates} from "../../data";
import {useNavigate} from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            labels: {
                color: 'rgba(255, 255, 255, 0.6)'
            }
        }
    }
}

const data = {
    labels: accounts.map((account) => account.name),
    datasets: [
        {
            label: 'Balance (USD): ',
            data: accounts.map((account) => getAccountBalance(account, true)),
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const Home = () => {
    const navigate = useNavigate()
    const [showAnalytics, setShowAnalytics] = useState(false);

    return <div className={s.wrapper}>
        <div className={s.content}>
            <div className={s.pane}>
                <div className={s.total}>TOTAL BALANCE</div>
                <div className={s.balance}>{getTotalBalance()}<span>USD</span></div>
            </div>
        </div>
        <div className={s.accounts}>
            <div className={cn(s.title, s.content)}>ACCOUNTS</div>
            <div className={s.list}>
                {accounts.map((account, index) => <div className={s.account} onClick={() => navigate(`/account/${index}`)}>
                    <div>{account.name}</div>
                    <div>{getAccountBalance(account)} <span>USD</span></div>
                </div>)}
            </div>
        </div>
        <div className={s.analytics}>
            <div className={cn(s.title, s.content)}>
                <span>ANALYTICS</span>
                <div className={cn(s.toggle, showAnalytics && s.active)} onClick={() => setShowAnalytics(!showAnalytics)}><div /></div>
            </div>
            {showAnalytics && <div className={s.chart}>
                <Doughnut data={data} options={options}/>
            </div>}
        </div>
        <div className={s.content} style={{ marginTop: 30 }}>
            <div className={s.title}>LATEST TRANSACTIONS</div>
            <div className={s.transactions}>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 1</div>
                        <div>14:22 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 0.0004 BTC</div>
                        <div>{(rates['BTC'] * 0.004).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 3</div>
                        <div>14:11 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.03 ETH</div>
                        <div>{(rates['ETH'] * 0.03).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 4</div>
                        <div>12:23 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 237 USDT</div>
                        <div>{(rates['USDT'] * 237).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 2</div>
                        <div>09:25 01.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 0.1 ETH</div>
                        <div>{(rates['ETH'] * 0.1).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 5</div>
                        <div>06:10 01.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 318 TRX</div>
                        <div>{(rates['TRX'] * 318).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 4</div>
                        <div>17:21 31.05.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 12 LTC</div>
                        <div>{(rates['LTC'] * 12).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 3</div>
                        <div>14:23 31.05.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 15 USDT</div>
                        <div>{(rates['USDT'] * 15).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 4</div>
                        <div>08:44 31.05.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 0.032 BTC</div>
                        <div>{(rates['BTC'] * 0.032).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 5</div>
                        <div>10:34 30.05.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.04 ETH</div>
                        <div>{(rates['ETH'] * 0.04).toFixed(2)} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>Account 2</div>
                        <div>06:17 30.05.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 117 USDT</div>
                        <div>{(rates['USDT'] * 117).toFixed(2)} USD</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Home;
