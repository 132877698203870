import s from './Currency.module.scss';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import {useNavigate, useParams} from "react-router-dom";
import {accounts, rates} from "../../data";
import {getAccountBalance, getAssetsBalance, getWalletBalance, getWalletsBalance} from "../../utils";
import cn from "classnames";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            labels: {
                color: 'rgba(255, 255, 255, 0.6)'
            }
        }
    }
}


const Currency = () => {
    const {account, currency, wallet} = useParams()
    const navigate = useNavigate()


    const currencyData = accounts[Number(account)].wallets[Number(wallet)].tokens[Number(currency)]


    return <div className={s.wrapper}>
        <div className={s.content}>
            <div className={s.top}>
                <div className={s.left} onClick={() => {
                    const currentAccount = Number(account)
                    const currentWallet = Number(wallet)
                    const currentCurrency = Number(currency)

                    if (currentCurrency === 0) {
                        navigate(`/account/${currentAccount}/wallet/${currentWallet}/${accounts[currentAccount].wallets[currentWallet].tokens.length - 1}`)
                    } else {
                        navigate(`/account/${currentAccount}/wallet/${currentWallet}/${currentCurrency - 1}`)
                    }
                }}><ArrowRight/></div>
                <div className={s.center} onClick={() => navigate(`/account/${Number(account)}/wallet/${Number(wallet)}`)}>{currencyData.currency}
                    <span> / {accounts[Number(account)].wallets[Number(wallet)].name} / {accounts[Number(account)].name}</span>
                </div>
                <div className={s.right} onClick={() => {
                    const currentAccount = Number(account)
                    const currentWallet = Number(wallet)
                    const currentCurrency = Number(currency)

                    if (currentCurrency === accounts[currentAccount].wallets[currentWallet].tokens.length - 1) {
                        navigate(`/account/${currentAccount}/wallet/${currentWallet}/0`)
                    } else {
                        navigate(`/account/${currentAccount}/wallet/${currentWallet}/${currentCurrency + 1}`)
                    }
                }}><ArrowRight/></div>
            </div>
        </div>
        <div className={s.content}>
            <div className={s.pane}>
                <div className={s.total}>TOTAL BALANCE</div>
                {/* @ts-ignore */}
                <div className={s.balance}>{(rates[currencyData.currency] * currencyData.amount).toFixed(2)} <span>USD</span></div>
            </div>
        </div>
        <div className={cn(s.buttons, s.content)}>
            <div onClick={() => navigate(`/account/${Number(account)}/wallet/${Number(wallet)}/${Number(currency)}/pay`)}>Pay</div>
            <div onClick={() => navigate(`/account/${Number(account)}/wallet/${Number(wallet)}/${Number(currency)}/receive`)}>Receive</div>
        </div>
        <div className={s.content}>
            <div className={s.title} style={{ marginTop: 20 }}>TRANSACTION HISTORY</div>
            <div className={s.transactions}>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>{currencyData.currency} <span>/ Wallet 2</span></div>
                        <div>14:22 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 0.0004 {currencyData.currency}</div>
                        {/* @ts-ignore */}
                        <div>{rates[currencyData.currency] * 0.0004} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>{currencyData.currency} <span>/ Assets</span></div>
                        <div>09:17 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.03 {currencyData.currency}</div>
                        {/* @ts-ignore */}
                        <div>{rates[currencyData.currency] * 0.03} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>{currencyData.currency} <span>/ Wallet 1</span></div>
                        <div>14:22 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 217 {currencyData.currency}</div>
                        {/* @ts-ignore */}
                        <div>{rates[currencyData.currency] * 217} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>{currencyData.currency} <span>/ Assets</span></div>
                        <div>17:34 01.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.017 {currencyData.currency}</div>
                        {/* @ts-ignore */}
                        <div>{rates[currencyData.currency] * 0.017} USD</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Currency;
