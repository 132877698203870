import s from './Confirm.module.scss'
import {useNavigate} from "react-router-dom";

const Confirm = () => {
    const navigate = useNavigate()
    return <div className={s.wrapper}>
        <div className={s.content}>
            <div className={s.pane}>
                <div className={s.total}>Payout #1492</div>
                <div className={s.balance}>5 000 000<span>USDT TRC-20</span></div>
            </div>
        </div>
        <div className={s.content} style={{marginTop: 20}}>
            <div className={s.pane}>
                <div className={s.total}>Recipient</div>
                <div className={s.balance}  style={{ fontSize: 20 }}>CarsRent LTD</div>
            </div>
        </div>
        <div className={s.content} style={{marginTop: 20}}>
            <div className={s.pane}>
                <div className={s.total}>Description</div>
                <div className={s.balance} style={{ fontSize: 20 }}>Winnings payments</div>
            </div>
        </div>
        <div className={s.content} style={{ marginTop: 20 }}>
            <div className={s.button} style={{ background: 'none', border: '1px solid var(--color-4)'}} onClick={() => navigate('/')}>Decline</div>
            <div className={s.button} onClick={() => navigate('/')}>Confirm</div>
        </div>
    </div>
}

export default Confirm

