export const rates = {
    BTC: 69138,
    USDT: 1,
    ETH: 3773,
    LTC: 83,
    TRX: 0.1137
}

export const accounts = [{
    name: 'Gate Express',
    wallets: [{
        name: 'Paymorph',
        tokens: [{
            currency: 'ETH',
            amount: 0.3
        }, {
            currency: 'USDT',
            amount: 218
        }],
    }, {
        name: 'BB Roling',
        tokens: [{
            currency: 'ETH',
            amount: 2.3
        }, {
            currency: 'USDT',
            amount: 10000.00
        }],
    }],
    assets: [{
        currency: 'USDT',
        amount: 1200
    }, {
        currency: 'ETH',
        amount: 1.2
    }, {
        currency: 'BTC',
        amount: 0.01
    }]
}, {
    name: 'KARDON',
    wallets: [{
        name: 'SALARY',
        tokens: [{
            currency: 'ETH',
            amount: 2.3
        }, ],
    }, {
        name: 'SETTLEMETS',
        tokens: [{
            currency: 'ETH',
            amount: 2.3
        }, {
            currency: 'USDT',
            amount: 1000000.00
        }],
    }],
    assets: [{
        currency: 'USDT',
        amount: 10582
    }]
}, {
    name: 'Personal Wallet',
    wallets: [{
        name: 'Costs',
        tokens: [{
            currency: 'USDT',
            amount: 2.3
        }, ],
    }, {
        name: 'Invest',
        tokens: [{
            currency: 'BTC',
            amount: 1000
        }],
    }],
    assets: [{
        currency: 'USDT',
        amount: 120000
    }]
}, {
    name: 'Team Jet',
    wallets: [{
        name: 'Business',
        tokens: [{
            currency: 'ETH',
            amount: 2.3
        }, {
            currency: 'USDT',
            amount: 2000000.00
        }],
    }, {
        name: 'Personal',
        tokens: [{
            currency: 'ETH',
            amount: 10
        },],
    }],
    assets: [{
        currency: 'USDT',
        amount: 56000.00
    }]
}, {
    name: 'Casino',
    wallets: [{
        name: 'Margin',
        tokens: [{
            currency: 'ETH',
            amount: 22.3
        }, {
            currency: 'USDT',
            amount: 1.003
        }],
    }, {
        name: 'Settlemetns',
        tokens: [{
            currency: 'USDT',
            amount: 2243242.30
        },],
    }],
    assets: [{
        currency: 'USDT',
        amount: 600
    }]
}]
