import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Account from "./pages/account";
import Wallet from "./pages/wallet";
import Assets from "./pages/assets";
import Currency from "./pages/currency";
import Receive from "./pages/receive";
import Pay from "./pages/pay";
import Confirm from "./pages/confirm";

function App() {
  return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/account/:account" element={<Account />} />
          <Route path="/account/:account/assets" element={<Assets />} />
          <Route path="/account/:account/wallet/:wallet" element={<Wallet />} />
          <Route path="/account/:account/wallet/:wallet/:currency" element={<Currency />} />
          <Route path="/account/:account/wallet/:wallet/:currency/receive" element={<Receive />} />
          <Route path="/account/:account/wallet/:wallet/:currency/pay" element={<Pay />} />
      </Routes>
  );
}

export default App;
