import s from './Pay.module.scss';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import {useNavigate, useParams} from "react-router-dom";
import {accounts, rates} from "../../data";
import {getAccountBalance, getAssetsBalance, getWalletBalance, getWalletsBalance} from "../../utils";
import cn from "classnames";
import QRCode from "react-qr-code";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            labels: {
                color: 'rgba(255, 255, 255, 0.6)'
            }
        }
    }
}


const Pay = () => {
    const {account, currency, wallet} = useParams()
    const navigate = useNavigate()


    const currencyData = accounts[Number(account)].wallets[Number(wallet)].tokens[Number(currency)]


    return <div className={s.wrapper}>
        <div className={s.content}>
            <div className={s.top}>
                <div className={s.center} onClick={() => navigate(`/account/${Number(account)}/wallet/${Number(wallet)}/${Number(currency)}`)}>{currencyData.currency}
                    <span> / {accounts[Number(account)].wallets[Number(wallet)].name} / {accounts[Number(account)].name}</span>
                </div>
            </div>
        </div>
        <div className={s.content}>

            <div className={s.input}>
                <input type="text" placeholder="Address"/>
            </div>
            <div className={s.input}>
                <input type="text" placeholder="Amount"/>
            </div>
            <div className={s.button}
                 onClick={() => navigate(`/account/${Number(account)}/wallet/${Number(wallet)}/${Number(currency)}`)}>Pay
            </div>
        </div>
    </div>
}

export default Pay;
