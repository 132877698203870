import s from './Assets.module.scss';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import {useNavigate, useParams} from "react-router-dom";
import {accounts, rates} from "../../data";
import {getAccountBalance, getAssetsBalance, getWalletBalance, getWalletsBalance} from "../../utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            labels: {
                color: 'rgba(255, 255, 255, 0.6)'
            }
        }
    }
}


const Assets = () => {
    const {account} = useParams()
    const navigate = useNavigate()


    const assetsData = accounts[Number(account)].assets

    const data = {
        labels: assetsData.map((asset) => asset.currency),
        datasets: [
            {
                label: 'Balance (USD): ',
                data: assetsData.map((asset) => {
                    // @ts-ignore
                    return asset.amount * rates[asset.currency]
                }),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return <div className={s.wrapper}>
        <div className={s.content}>
            <div className={s.top}>
                <div className={s.center} onClick={() => navigate(`/account/${Number(account)}`)}>Assets <span>/ {accounts[Number(account)].name}</span></div>
            </div>
        </div>
        <div className={s.content}>
            <div className={s.pane}>
                <div className={s.total}>TOTAL BALANCE</div>
                <div className={s.balance}>{getAssetsBalance(accounts[Number(account)])} <span>USD</span></div>
            </div>
            <div className={s.title} style={{ marginTop: 20 }}>BALANCES</div>
            <div className={s.balances}>
                {assetsData.map(asset => <div className={s.token}>
                    <span>{asset.currency}</span>
                    <div>
                        <div>{asset.amount} {asset.currency}</div>
                        {/* @ts-ignore */}
                        <div>{(rates[asset.currency] * asset.amount)?.toFixed(2)} USD</div>
                    </div>
                </div>)}
            </div>
        </div>
        <div className={s.chart}>
            <Doughnut data={data} options={options}/>
        </div>
        <div className={s.content}>
            <div className={s.title}>TRANSACTION HISTORY</div>
            <div className={s.transactions}>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>BTC <span>/ Wallet 2</span></div>
                        <div>14:22 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 0.0004 BTC</div>
                        <div>{rates['BTC'] * 0.0004} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>ETH <span>/ Assets</span></div>
                        <div>09:17 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.03 ETH</div>
                        <div>{rates['ETH'] * 0.03} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>USDT <span>/ Wallet 1</span></div>
                        <div>14:22 02.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>+ 217 USDT</div>
                        <div>{rates['USDT'] * 217} USD</div>
                    </div>
                </div>
                <div className={s.transaction}>
                    <div className={s.left}>
                        <div>BTC <span>/ Assets</span></div>
                        <div>17:34 01.06.2024</div>
                    </div>
                    <div className={s.right}>
                        <div>— 0.017 BTC</div>
                        <div>{rates['BTC'] * 0.017} USD</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Assets;
